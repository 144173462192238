// Global variables
$font-family: Roboto, Helvetica, Arial, sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-size: 14px;
$primary-font: normal normal 14px/16px Roboto;

$regular: 400;
$medium: 500;
$bold: 700;

// Colors
$black: #000;
$orange: #ff8811;
$orange-dark: #fc6621;
$aqua: #33a49f;
$aqua-dark: #258782;
$white: #fff;
$red: #e43030;
$grey: #acb5cd;
$grey-dark: #8f94a0;
$dark: #202942;
$violet: #5e73ae;
$violet-light: #6d88d2;
$violet-dark: #313d5f;
$skeleton-bg: rgba($violet, 0.9);

//ColorsRedesign
$accent: #00b8b8;
$accentHover: #009495;
$secondAccent: #fc6621;
$secondAccentHover: #f65003;
$mainText: #ffffff;
$secondaryText: #a4a4a4;
$background: #141414;
$background-promo: #ffffff;
$background-second-promo: #f1f1f1;
$blocksBg: #1c1c1c;
$buttonsBg: #404040;
$secondaryItems: #434343;
$disabledButton: #363636;
$overlay: rgba(0, 0, 0, 0.7);
$success: #1bdc68;
$error: #e43030;
$tvChannelCard: radial-gradient(108.42% 108.42% at 50% 50%, #ffffff 0%, #171717 100%);
$footerBackground: #111111;
$footerCopywrightBackground: #0c0c0c;

// Components colors
$body-bg: #212b43;
$bg: #313f65;
$header-bg: #31405f;
$footer-bg: #1c243a;
$footer-bg-nav: $violet-dark;
$footer-text: $grey;

$modal-bg: #eff2f5;

// Gradients
$gradient-aquamarine: linear-gradient(180deg, #2fbbb2 0%, #92d7c2 100%);
$gradient-violet: linear-gradient(180deg, #576ca8 0%, #6e82bd 100%);
$gradient-orange: linear-gradient(180deg, #ff6e2c 0%, #ffa448 100%);
$gradient-video: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, $violet-dark 100%);

// Shadows
$box-shadow-lg: 0 20px 50px rgba(0, 0, 0, 0.1);
$box-shadow-md: 0 15px 20px rgba(0, 0, 0, 0.05);

// Header + nav
$header-height-mobile: 56px;
$header-height-desktop: 74px;
$nav-link-color: $white;
$nav-link-hover-color: $aqua-dark;
$nav-link-active-color: $aqua;
$nav-link-transition: 0.25s ease;

// Some sizes
$border: 1px solid #cfd0d1;
$b-radius: 3px;
$line-height: 1.1667;
$transition: 0.25s ease;

// Container
$container-width: 1280px;
$container-width-hd: 1440px;

$container-margin: 0 auto;
$container-padding: 0 1rem;
$container-padding-sd: 0 2rem;
$container-padding-hd: 0 5rem;

// Media queries
$desktop-hd: 1440px;
$desktop-sd: 1280px;
$tablet: 720px;
$mobile-landscape: 560px;
$mobile: 360px;
