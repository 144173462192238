@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.Footer {
  z-index: 1;
  width: 100%;
  color: $footer-text;
  background-color: $footer-bg;

  &__heading {
    color: $mainText;
    text-transform: uppercase;
    @include typography(subtitle, $bold);
  }

  &__links-wrap {
    display: flex;
    gap: 50px;
  }

  ul {
    white-space: nowrap;
  }

  // Navigation
  &Nav {
    background-color: $footerBackground;
    @media (min-width: $desktop-hd) {
      padding-bottom: 12px;
    }

    &__wrap {
      @media (min-width: $tablet) {
        display: flex;
        flex-wrap: wrap;
      }
      @media (min-width: $desktop-sd) {
        gap: 50px;
      }
      @media (min-width: $desktop-hd) {
        flex-grow: 1;
      }
    }

    nav {
      @media (min-width: $desktop-hd) {
        display: flex;
        justify-content: space-between;
      }

      & .FooterNav__wrap section {
        @media (min-width: $tablet) {
          width: initial;
        }
      }
    }

    & .FooterNav__wrap section:nth-child(1) {
      @media (min-width: $tablet) {
        min-width: 700px;
      }
      @media (min-width: $desktop-sd) {
        min-width: initial;
      }

      & div:nth-child(3) {
        display: none;
        @media (min-width: $tablet) {
          display: initial;
        }
      }
    }

    & .FooterNav__wrap section:nth-child(2) {
      padding-top: 0;
      @media (min-width: $tablet) {
        display: none;
      }
    }

    & .FooterNav__wrap section:nth-child(3) {
      @media (min-width: $tablet) {
        padding-top: 50px;
        min-width: 700px;
      }
      @media (min-width: $desktop-sd) {
        min-width: initial;
        padding-top: 1rem;
      }
      @media (min-width: $desktop-hd) {
        min-width: initial;
        padding-top: 40px;
      }
      @media (min-width: $desktop-hd) {
        margin: 0 auto;
      }
    }

    section {
      width: 100%;
      padding-top: 1rem;

      @media (min-width: $desktop-hd) {
        padding-top: 40px;
      }

      &:last-of-type {
        border-bottom: none;
      }

      // @media (min-width: $desktop-sd) {
      //   padding: 0;
      //   border-bottom: none;
      //   margin-top: 2.5rem;
      //   margin-bottom: 1.5rem;
      // }

      // &:first-of-type {
      //   @media (min-width: $desktop-sd) {
      //     width: 33.5%;
      //   }
      // }

      // &:nth-child(2) {
      //   @media (min-width: $desktop-sd) {
      //     width: 27%;
      //   }
      // }

      // &:last-of-type {
      //   @media (min-width: $desktop-sd) {
      //     width: 26%;
      //   }
      // }
    }

    ul {
      margin: 12px 0 0;
      list-style-type: none;
      padding: 0;

      @media (min-width: $tablet) {
        margin: 12px 0;
      }

      & li {
        margin-bottom: 16px;
      }

      a,
      span {
        @include typography(subtitleSm, $regular);
        color: $secondaryText;
        text-decoration: none;
        transition: 0.25s ease;
        white-space: normal;

        &:hover {
          color: $mainText;
        }
      }
    }

    &__soc-links {
      display: flex;

      a {
        margin-right: 1.5rem;

        &:last-of-type {
          margin-right: 0;
        }
      }

      svg {
        & path {
          fill: $secondaryText;
        }
        &:hover {
          path {
            fill: $white;
          }
        }
      }
    }

    &__apps {
      flex-direction: column;
      justify-content: start !important;
      flex-wrap: wrap;

      ul {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        margin-right: 12px;
        margin-bottom: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      a {
        display: flex;
      }

      svg {
        * {
          transition: $transition;
        }

        // &:hover {
        //   path:first-of-type {
        //     fill: darken(#1c243a, 20%);
        //   }
        // }
      }
    }
  }

  // Copyright
  &Copyright {
    color: $secondaryText;
    background: $footerCopywrightBackground;
    @include typography(subtitle, $regular);

    & .Container {
      padding: 12px 16px;
      @media (min-width: $tablet) {
        display: flex;
        padding: 16px 32px;
        justify-content: space-between;
      }
      @media (min-width: $desktop-sd) {
        padding: 20px 32px;
      }
      @media (min-width: $desktop-hd) {
        padding: 20px 80px;
      }

      & p {
        margin: 0 auto;
        text-align: center;
      }
    }

    & a {
      color: $accent;

      & span {
        color: $secondaryText;
      }
    }

    & p {
      margin: 0;
      max-width: 1280px;

      &:first-child {
        margin-bottom: 12px;
      }

      @media (min-width: $desktop-hd) {
        padding: 20px 0;
      }
    }
  }
}

section.FooterNav__apps {
  padding-top: 4px;
  @media (min-width: $desktop-hd) {
    max-width: 264px;
    padding-top: 40px;
  }
  & ul {
    margin-bottom: 0;
    @media (min-width: $tablet) {
      padding-right: 32px;

      & li {
        margin-bottom: 32px;
      }
    }

    @media (min-width: $desktop-hd) {
      padding-right: 0;

      & li {
        margin-bottom: 10px;
        &:nth-child(2n + 1) {
          margin-right: 12px;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  & li {
    @media (min-width: $mobile-landscape) {
      max-width: 107px;
      // & a svg {
      //   height: 34px;
      // }
    }
    @media (min-width: $desktop-hd) {
      max-width: initial;
      // & a svg {
      //   height: initial;
      // }
    }
  }
  & a {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    padding: 1px;
    border-radius: 7px;

    @media (min-width: $mobile-landscape) {
      & svg {
        height: 34px;
        border-radius: 5px;
      }
    }
    @media (min-width: $desktop-hd) {
      max-width: initial;
      & svg {
        border-radius: 7px;
        height: initial;
      }
    }

    &:hover {
      background: $accent;
    }
  }
}

section.FooterNav__soc {
  &--mobile {
    @media (min-width: $desktop-hd) {
      display: none;
    }
  }
  &--desktop {
    display: none;
    @media (min-width: $desktop-hd) {
      display: block;
      padding-top: 10px;
    }
  }
}
